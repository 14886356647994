/*
  Application configuration

  Config properties mapped from rails:
  @see app/views/public/shared/_javascript_configuration.html.erb

  env       -> Static non mutable environment variables
  user      -> User specific variables
  runtime   -> Mutable application variables
*/
class Configuration {
  constructor(config) {
    this.config = {
      user: {},
      runtime: {
        locale: 'en',
      },
      env: {},
      ...config,
    };
  }

  get userIsLoggedIn() {
    return this.config.user.id && !window.location.pathname.includes('authenticate');
  }

  get inProductionEnv() {
    return this.env.RAILS_ENV === 'production';
  }

  get inDevEnv() {
    return this.env.RAILS_ENV === 'dev';
  }

  get inDevelopmentEnv() {
    return this.env.RAILS_ENV === 'development';
  }

  get inTestEnv() {
    return this.env.RAILS_ENV === 'test';
  }

  // --------------

  get user() {
    return this.config.user;
  }

  set user(config) {
    this.config.user = config;
    this.updated();
  }

  get runtime() {
    return this.config.runtime;
  }

  set runtime(config) {
    this.config.runtime = config;
    this.updated();
  }

  get env() {
    return this.config.env;
  }

  set env(config) {
    this.config.env = config;
    this.updated();
  }

  resetAll() {
    this.resetUser();
    this.resetEnv();
    this.resetRuntime();

    return this.config;
  }

  resetUser() {
    this.config.user = {};

    return this.config.user;
  }

  resetEnv() {
    this.config.env = {};

    return this.config.env;
  }

  resetRuntime() {
    this.config.runtime = {};

    return this.config.runtime;
  }

  refreshConfigFromMetaTag() {
    const el = document.head.querySelector('[name="app-script-config"]');
    if (!el) { return false; }

    const content = el.getAttribute('content');
    const newConfig = JSON.parse(content);
    this.config = newConfig;
    this.updated();

    // We need to add remove here because when we go back
    // with turbolinks we don't want to load old configuration.
    document.head.removeChild(el);

    return true;
  }

  updated() {
    document.dispatchEvent(
      new CustomEvent('app:config:updated', {
        detail: this.config,
      }),
    );
  }
}

export const AppConfig = new Configuration();

export default Configuration;

// Exports
window.App ||= {};
window.App.config = AppConfig;

// Load instantly we get to here and here meta tags in head are already loaded
AppConfig.refreshConfigFromMetaTag();

// Load before-render as turbolinks already replaces head tags
document.addEventListener('turbolinks:before-render', () => AppConfig.refreshConfigFromMetaTag());
